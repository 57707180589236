<template>
  <div>
    <b-row
      align-h="center"
      class="mt-3"
    >
      <b-col lg="8">
        <b-card header="Create Role In Entity">
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(createRoleInEntity)">
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="formData.title"
                    type="text"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="Description"
                rules="required"
              >
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="formData.description"
                    type="text"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-button
                type="submit"
                variant="primary"
              >
                Add
              </b-button>
              <back />

            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'CreateRoleInEntity',
  components: { Back },
  data() {
    return {
      formData: {
        selectedInpoId: this.$store.getters['mainEntity/getEntityId'],
      },
    }
  },
  setup() {
    const { successfulOperationAlert, failedOperationAlert } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert,
      failedOperationAlert,
      getValidationState,

    }
  },
  methods: {
    createRoleInEntity() {
      this.$portalUsers.post('v1/role', {
        entity: this.formData.selectedInpoId,
        title: this.formData.title,
        description: this.formData.description,
      }).then(() => {
        this.successfulOperationAlert('Role is created successfully')
        this.$router.push({ name: 'roles-list' })
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
